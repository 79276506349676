<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div v-if="gps.account" class="form-group col-sm-12">
          <label for="parent_id">Account:</label>
          <input class="form-control" disabled name="account_id" type="text" v-model="gps.account.account_name">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="gps_code">IMEI:</label>
        <input class="form-control" required="" name="imei" type="text" v-model="gps.imei">
      </div>
      <div class="form-group col-sm-12 required">
          <label for="name">Serial Number:</label>
          <input class="form-control" required="" name="serial_number" type="text" v-model="gps.serial_number">
      </div>
      <div class="form-group col-sm-12">
          <label for="name">Model:</label>
          <input class="form-control" name="model" type="text" v-model="gps.model">
      </div>
      <div class="form-group col-sm-6">
          <label for="fuel_quantity">Kilometers per 1 liter:</label>
          <input class="form-control" name="model" type="number" v-model.number="gps.fuel_quantity" step="any">
      </div>
      <div class="form-group col-sm-6">
          <label for="fuel_price">Cost for 1 liter:</label>
          <input class="form-control" name="model" type="number" v-model.number="gps.fuel_price" step="any">
      </div>
      <div class="form-group col-sm-12">
          <label for="sensor_id">Sensor Installed:</label>
          <div class=" d-flex">
            <div v-for="sensor in sensors" class="form-check mr-3" :key="sensor">
              <input class="form-check-input" type="checkbox" :id="sensor.id" :value="sensor.id" v-model="sensor_id">
              <label :for="sensor.id" class="form-check-label">{{sensor.sensor_name}}</label>
            </div>
          </div>
      </div>
      <div class="form-group col-sm-12">
          <label for="parent_id">Sim Card:</label>
          <Select2 name="sim_card_id" v-model.number="gps.sim_card_id" :options="simCardsOptions" />
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save" :disabled="this.onRequest">
        <router-link class="btn btn-default ml-1" to="/gps">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('gps', ['gps', 'onRequest']),
        ...mapState('sim_cards', ['sim_cards']),
        ...mapState('sensors', ['sensors']),
    },
    data(){
      return {
        sensor_id: [],
        simCardsOptions: [],
      }
    },
    components:{
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.gps.sensors = this.sensor_id
        this.edit(this.gps)
      },
      ...mapActions('gps', ['edit', 'get']),
      ...mapActions('sim_cards', {getSimCards: 'get_sim_card_by_account'}),
      ...mapActions('sensors', {getSensors: 'get_all'}),
    },
    created() {
      this.$emit('onChildInit', 'Edit GPS')
      this.getSensors()
      this.get(this.$route.params.id)
    },
    watch: {
      gps (nextState, prevState) {
        if(nextState !== prevState){
          if(nextState.account_id !== prevState.account_id && nextState.account_id > 0){
            this.getSimCards({account_id: nextState.account_id})
          }
          if(nextState.sensors){
            this.sensor_id = []
            nextState.sensors.map(item => {
              if(item.sensor){
                this.sensor_id.push(item.sensor.id)
              }
            })
          }
          return
        }
      },
      sim_cards(nextState, prevState){
        if(nextState !== prevState){
          this.simCardsOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.simCardsOptions.push({id:item.id, text:item.gsm_no})
            })
          }
          return
        }
      }
    },
  }
</script>
