<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div v-if="gps_camera.account" class="form-group col-sm-12">
          <label for="parent_id">Account:</label>
          <input class="form-control" disabled name="account_id" type="text" v-model="gps_camera.account.account_name">
      </div>
      <div v-if="gps_camera.gps" class="form-group col-sm-12 required">
          <label for="name">GPS:</label>
          <input class="form-control" disabled ="" name="gps_id" type="text" v-model="gps_camera.gps.serial_number">
      </div>
      <div class="form-group col-sm-12 required">
          <label for="name">Camera Name:</label>
          <input class="form-control" required="" name="camera_name" type="text" v-model="gps_camera.camera_name">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="gps_camera_code">Show Widget:</label>
        <select class="form-control" name="show_widget" v-model.number="gps_camera.show_widget" required>
          <option value="1" style="color:black;">True</option>
          <option value="2" style="color:black;">False</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/gps_cameras">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    computed: {
        ...mapState('gps_cameras', ['gps_camera', 'onRequest']),
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.edit(this.gps_camera)
      },
      ...mapActions('gps_cameras', ['edit', 'get']),
    },
    created() {
      this.$emit('onChildInit', 'Edit GPS Camera')
      this.get(this.$route.params.id)
    }
  }
</script>
