<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="parent_id">Account:</label>
        <Select2 name="account_id" v-model.number="gps_camera.account_id" :options="accountOptions"  @change="onChange($event)" @select="onChange($event)"/>
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="gps_id">GPS:</label>
        <Select2 name="gps_id" v-model.number="gps_camera.gps_id" :options="gpsOptions" />
      </div>
      <div class="form-group col-sm-12 required">
          <label for="name">Camera Name:</label>
          <input class="form-control" required="" name="camera_name" type="text" v-model="gps_camera.camera_name">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="gps_camera_code">Show Widget:</label>
        <select class="form-control" name="show_widget" v-model.number="gps_camera.show_widget" required>
          <option value="1" style="color:black;">True</option>
          <option value="2" style="color:black;">False</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/gps_cameras">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('gps', ['gpss']),
        ...mapState('gps_cameras', ['onRequest']),
    },
    data(){
      return {
        gps_camera: {
          camera_name: '',
          show_widget: 1,
          gps_id: '',
          account_id: '',
        },
        accountOptions: [],
        gpsOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        const {gps_camera} = this
        this.add(gps_camera)
      },
      onChange(event){
        this.getGps({account_id: parseInt(event.id)})
        this.route_id = ''
      },
      ...mapActions('gps_cameras', ['add']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('gps', {getGps: 'get_gps_by_account'}),
    },
    components:{
      Select2,
    },
    created() {
      this.$emit('onChildInit', 'Add New Sim Card')
      this.get_all()
    },
    watch:{
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      gpss(nextState, prevState){
        if(nextState !== prevState){
          this.gpsOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.gpsOptions.push({id:item.id, text:item.imei + ' - '+item.serial_number})
            })
          }
          return
        }
      },
    }
  }
</script>
