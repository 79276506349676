<template>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h4 class="m-0 text-dark">{{text}}</h4>
        </div>
        <div v-if="addBtn" class="col-sm-6">
          <ol class="breadcrumb float-right">
            <router-link class="btn btn-primary" :to="addBtn">Add New</router-link>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: ['text', 'addBtn']
}
</script>
